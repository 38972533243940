body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




header {

  border-bottom: 1px solid #eee;
  

 
}

/* header .right-box {

  width: calc(100%-20px);
  height: 280px;
  
} */

header .left-box {


  background-color: white;
}

header div figure {

  padding:20px;
  margin: 0px;

}

header div figure img {

  margin:0;
  width: 100%;
  height:auto;

}

a { text-decoration: none;}

nav {

  clear: both;
  padding-top: 10px;

}

nav ul  {

  margin: 0px;
  padding:0px
  
}


nav ul li {

  list-style-type: none;
  display: inline-block;
  margin-left: 10px;
  
}


.boton {

  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color:white;
  border-radius: 20px;
  padding: 5px 15px 5px 15px;
  background-image: linear-gradient(#fca5a5, red);

  box-shadow: 3px 3px 10px lightgrey;
  

}


#root {


  margin-left: auto;
  margin-right: auto;


}

 #root div section {

  display: grid;
  grid-template-columns: auto auto auto auto; 
  justify-content: left;
  grid-gap: 15px;
}

#root {

  padding: 30px;

}

content h1 {

 color: red;


}




.card {

  background-color: white;
  padding: 10px;
  border-radius: 20px;
  border: 3px red solid;
}

.card figure {

  position: relative;
  padding: 0;
  margin: 0;
  
}

.card figure .logo-municipalidad {

  position: absolute;
  z-index: 1;
  top:5px;
  right:5px;
  width: 75px;
  height: auto;

}

.card figure .imagen {

  width: 100%;
  height: auto;
  border-radius: 20px;
  z-index: -1;

}
.card h2 {

  text-transform: uppercase;
  color:red;
}

.card h2, .card p {

text-align: center;

}

.card ul, .card li {

  margin: 0;
  padding: 0;

}
.card ul {

  text-align: center;

}

.card ul li {

  margin: 5px;
  display:inline-block;
}

.card ul li a {
  vertical-align: middle;
  margin: 3px;
}

.card .boton {

  text-align: center;

}

.card .icon {

  width: 20px;
  height: auto;
  vertical-align: middle;
  margin: 3px;

}



footer {
  
  height: 40px;
  background-color: royalblue;
}


@media only screen and (max-width: 1200px) { 


  #root div section {

    grid-template-columns: auto auto auto;
  }
  



}



@media only screen and (max-width: 700px) { 


  header {

   
    grid-template-columns: auto;

   
  }

  


  header img {

    width: 100%;
    height: auto;
    
  }




 #root div section {

    grid-template-columns: auto;
  }
  
  nav ul li {

    list-style-type: none;
    display: inline-block;
    margin: 10px;
    
  }

  nav ul {

    text-align: center;

  }
  
  .boton {

    font-size:14px;
  }

  #root {

    padding: 10px;
  }

  h1 {

    text-transform: uppercase;
    font-size: 22px;
    text-align: center;

  }


}